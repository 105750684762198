<template>
  <div class="Result">
    <gold-box class="Result__Main">
      <h1 class="Result__Title ResultTitle">
        <img
          :src="require(`@images/result/${locale}/title.png`)"
          :alt="$t('result.001')"
        >
      </h1>
      <p class="GoldBox__Block" v-html="$t('result.002')" />
      <ol class="Result__List ResultList">
        <li
          v-for="(item,i) in rankingList"
          :key="i"
          class="ResultList__Item ResultListItem"
        >
          <div class="ResultListItem__Image" :class="[`-rank${item.rank}`]">
            <div class="ResultListItem__ImageInner">
              <img class="" :src="require(`@images/characters/result/${item.heroId}.png`)" alt="">
            </div>
          </div>
          <div class="ResultListItem__Detail ResultListItemDetail">
            <div class="ResultListItemDetail__Nickname" v-text="$t(`nickname.${item.heroId}`)" />
            <div class="ResultListItemDetail__Name" v-text="$t(`character.${item.heroId}`)" />
            <div class="ResultListItemDetail__Count" v-text="`${delimit(item.voteCount)}` + $t('result.003')" />
          </div>
        </li>
      </ol>
      <btn-decoration v-if="siteOpend" class="Result__NextButton" @click="clickBtnDecoration">
        <img
          :src="require(`@images/result/${locale}/text_voting-match.png`)"
          :alt="$t('result.004')"
        >
      </btn-decoration>
    </gold-box>
    <paging class="Result__Paging" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { delimit } from '@/libraries/util'
import LocaleMixin from '@/mixins/locale'
import LoaderMixin from '@/mixins/loader'
import GoldBox from '@/components/global/gold-box.vue'
import BtnDecoration from '@/components/global/button-decoration.vue'
import Paging from '@/components/result/paging.vue'

export default {
  name: 'Result',
  mixins: [LocaleMixin, LoaderMixin],
  components: {
    GoldBox,
    BtnDecoration,
    Paging
  },
  computed: {
    ...mapGetters('result', { rankingList: 'getTopEightRanking' }),
    siteOpend () {
      return true
    }
  },
  methods: {
    clickBtnDecoration () {
      window.open('https://support.fire-emblem-heroes.com/voting_gauntlet/howtoplay', '_blank', 'noopener noreferrer')
    },
    delimit (count) {
      return delimit(count, this.locale)
    }
  }
}
</script>

<style lang="scss" scoped>
.Result {
  @at-root {
    & {
      margin-bottom: 34px;
    }
    // NOTE: override generic component
    & .Result__Main {
      margin: 177px auto 166px;
      background-position: center 270px, center 0;
    }
    & .GoldBox__Block {
      position: relative;
      margin-top: 17px;
      padding: 40px 75px;
    }
    .Result__Title {
      margin-top: -220px;
    }
    .Result__NextButton {
      margin-bottom: -10px;
    }
    .Result__Paging {
      margin: 0 20px 40px;
      padding-top: 20px;
    }
  }
}

.ResultTitle {
  @at-root {
    & {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 628px;
      height: 207px;
      padding: 115px 0 15px;
      background: url(#{$img-path}result/bg_title.png) no-repeat 0 0;
      text-align: center;
    }
  }
}

.ResultList {
  @at-root {
    & {
      display: flex;
      flex-wrap: wrap;
      width: 522px;
      margin: 60px auto 20px;// 分離
      list-style: none;
      padding: 0;
    }
  }
}

.ResultListItem {
  @at-root {
    & {
      position: relative;
      height: 520px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 60px;
    }
    .ResultListItem__Image {
      position: relative;
      width: 100%;
      height: 416px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 24px;
        z-index: 20;
        display: block;
        width: 62px;
        height: 63px;
        background-repeat: no-repeat;
      }
      &.-rank1::before {
        background-image: url(#{$img-path}icon/ico_rank1.png);
      }
      &.-rank2::before {
        background-image: url(#{$img-path}icon/ico_rank2.png);
      }
      &.-rank3::before {
        background-image: url(#{$img-path}icon/ico_rank3.png);
      }
      &.-rank4::before {
        background-image: url(#{$img-path}icon/ico_rank4.png);
      }
      &.-rank5::before {
        background-image: url(#{$img-path}icon/ico_rank5.png);
      }
      &.-rank6::before {
        background-image: url(#{$img-path}icon/ico_rank6.png);
      }
      &.-rank7::before {
        background-image: url(#{$img-path}icon/ico_rank7.png);
      }
      &.-rank8::before {
        background-image: url(#{$img-path}icon/ico_rank8.png);
      }
      &.-rank2 {
        img {
          transform: translate(0, -8px);
        }
      }
      &.-rank3 {
        img {
          transform: translate(-10px, 0);
        }
      }
      &.-rank4 {
        img {
          transform: translate(10px, 40px);
        }
      }
      &.-rank5 {
        img {
          transform: translate(0, -8px);
        }
      }
      &.-rank6 {
        img {
          transform: translate(-16px, 0);
        }
      }
      &.-rank8 {
        img {
          transform: translate(0, -6px);
        }
      }
    }
    .ResultListItem__ImageInner {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}

.ResultListItemDetail {
  @at-root {
    & {
      position: relative;
      z-index: 1;
      width: 220px;
      height: 104px;
      border-style: solid;
      border-image: url(#{$img-path}result/bg_character-detail.png) 0 36 fill / auto auto stretch;
      line-height: 1.2;
      padding: 18px 0 0 0;
    }
    .ResultListItemDetail__Nickname,
    .ResultListItemDetail__Name,
    .ResultListItemDetail__Count {
      text-align: center;
    }
    .ResultListItemDetail__Nickname {
      font-size: $fs-middle;
    }
  }
}
</style>
